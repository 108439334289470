import React from 'react'
import { graphql } from 'gatsby'

import {
  Section,
  Container,
  Row,
  Column,
  Text,
  Heading,
  Box,
  Hr,
  Grid,
} from 'components'

import Layout from 'components/Layout'

import Article from '../components/Article'
import Audience from '../components/Audience'
import Tags from '../components/Tags'
import Sidebar from '../components/Sidebar'
import LeadForm from '../components/LeadForm'

import { useLocation } from 'context/location'

const Page = ({ data }) => {
  const {
    layout: { nodes: layout },
    articles: { nodes: articles },
    audiences: { nodes: audiences },
    tags: { nodes: tags },
  } = data

  const {
    featuredSmall: featuredSmallDE,
    featuredSmallAT,
    featuredLarge: featuredLargeDE,
    featuredLargeAT,
    outlineList: outlineListDE,
    outlineListAT,
    highlighted: highlightedDE,
    highlightedAT,
  } = layout[0]

  const usedArticleIdsDE = [
    ...featuredSmallDE,
    ...featuredLargeDE,
    ...outlineListDE,
    ...highlightedDE,
  ].map(({ id }) => id)

  const usedArticleIdsAT = [
    ...featuredSmallAT,
    ...featuredLargeAT,
    ...outlineListAT,
    ...highlightedAT,
  ].map(({ id }) => id)

  const { countryCode } = useLocation()

  const countryCodeToUse =
    countryCode !== 'DE' && countryCode !== 'AT' ? 'AT' : countryCode

  const countrySpecificArticles = articles.filter(({ countries }) => {
    if (!countries) {
      return true
    }
    if (countries.length === 0) {
      return true
    }

    return countries.includes(countryCodeToUse.toLowerCase())
  })

  const unusedArticlesDE = countrySpecificArticles.filter(
    ({ id }) => !usedArticleIdsDE.includes(id)
  )

  const unusedArticlesAT = countrySpecificArticles.filter(
    ({ id }) => !usedArticleIdsAT.includes(id)
  )

  const featuredSmall =
    countryCodeToUse === 'DE' ? featuredSmallDE : featuredSmallAT

  const featuredLarge =
    countryCodeToUse === 'DE' ? featuredLargeDE : featuredLargeAT

  const outlineList = countryCodeToUse === 'DE' ? outlineListDE : outlineListAT

  const highlighted = countryCodeToUse === 'DE' ? highlightedDE : highlightedAT

  const unusedArticles =
    countryCodeToUse === 'DE' ? unusedArticlesDE : unusedArticlesAT

  return (
    <Layout
      seo={{
        title: 'Bambus Ratgeber',
        description:
          'Das Magazin von Bambus: wir decken Themen rund um Ihre Immobilie und Finanzen ab.',
      }}
    >
      <Section spacing="small">
        <Container>
          <Row flexWrap="wrap">
            <Column size={[1, 1, 7 / 12, 8 / 12]}>
              <Box sx={{ display: 'flex' }}>
                <Text sx={{ fontSize: 0, ml: 1 }}>Ratgeber</Text>
                <Text variant="muted" sx={{ ml: 1 }}>
                  / Alle
                </Text>
              </Box>

              <Box mt={3} />

              <Audience audiences={audiences} selectedAudienceId="all" />

              <Box sx={{ display: ['block', null, 'none'] }}>
                <Heading.H5 sx={{ pt: 4, pb: 2 }}>Themenauswahl</Heading.H5>
                <Tags tags={tags} />
                <Hr />
              </Box>

              <Heading.H2
                as="h1"
                sx={{
                  pt: 4,
                  pb: 6,
                  '@media (max-width: 320px)': {
                    fontSize: 30,
                  },
                }}
              >
                Der Ratgeber für Immobilienbesitzer
              </Heading.H2>

              <Box>
                <Row
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                  }}
                >
                  {featuredSmall.map((article) => (
                    <Column
                      key={article.id}
                      size={[1, null, null, 1 / 2]}
                      sx={{ mb: 8 }}
                    >
                      <Article {...article} />
                    </Column>
                  ))}
                  {featuredLarge.map((article) => (
                    <Column
                      key={article.id}
                      size={[1, null, null, null]}
                      sx={{ mb: 8 }}
                    >
                      <Article {...article} />
                    </Column>
                  ))}
                </Row>

                <Box
                  sx={{
                    border: '2px solid',
                    borderColor: 'colorBlue10',
                    py: 6,
                    px: [3, 6],
                    mb: 8,
                  }}
                >
                  <Heading.H3
                    sx={{ textAlign: ['center', null, 'left'], pb: 6 }}
                  >
                    Zum Thema Kredit
                  </Heading.H3>

                  <Grid gap={5} columns={1}>
                    {outlineList.map((article) => (
                      <Article key={article.id} {...article} isInline />
                    ))}
                  </Grid>
                </Box>

                <Box
                  sx={{
                    display: ['flex', null, 'none'],
                    justifyContent: 'center',
                    mb: 6,
                  }}
                >
                  <LeadForm />
                </Box>

                <Box
                  sx={{
                    px: [3, 6],
                    py: 6,
                    mb: 8,
                    bg: 'colorBlue5',
                  }}
                >
                  <Heading.H3 sx={{ pb: 6, textAlign: 'center' }}>
                    Meistgelesene Artikel
                  </Heading.H3>

                  <Row
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      justifyContent: 'space-between',
                    }}
                  >
                    {highlighted.map((article) => (
                      <Column
                        key={article.id}
                        size={[1, null, null, 1 / 2]}
                        sx={{ mb: 8 }}
                      >
                        <Article {...article} />
                      </Column>
                    ))}
                  </Row>
                </Box>

                <Box>
                  <Heading.H3 sx={{ pb: 6, textAlign: 'center' }}>
                    Empfehlungen der Redaktion
                  </Heading.H3>

                  <Row
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      justifyContent: 'space-between',
                    }}
                  >
                    {unusedArticles.map((article) => (
                      <Column
                        key={article.id}
                        size={[1, null, null, 1 / 2]}
                        sx={{ mb: 8 }}
                      >
                        <Article {...article} />
                      </Column>
                    ))}
                  </Row>
                </Box>
              </Box>
            </Column>
            <Column
              id="StickySidebarBottomBoundary"
              size={[1, 1, 5 / 12, 4 / 12]}
            >
              <Box sx={{ display: ['none', null, 'block'] }}>
                <Box>
                  <Heading.H5 sx={{ pb: 2 }}>Themenauswahl</Heading.H5>
                  <Tags tags={tags} />
                </Box>

                <Hr />

                <Sidebar bottomBoundarySelector="#StickySidebarBottomBoundary"></Sidebar>
              </Box>
            </Column>
          </Row>
        </Container>
      </Section>
    </Layout>
  )
}

export default Page

export const pageQuery = graphql`
  query {
    layout: allContentfulGuideLayout(filter: { node_locale: { eq: "de" } }) {
      nodes {
        featuredLarge {
          ...Article
        }
        featuredLargeAT {
          ...Article
        }
        featuredSmall {
          ...Article
        }
        featuredSmallAT {
          ...Article
        }
        highlighted {
          ...Article
        }
        highlightedAT {
          ...Article
        }
        outlineList {
          ...Article
        }
        outlineListAT {
          ...Article
        }
      }
    }
    articles: allContentfulArticle(
      filter: {
        node_locale: { eq: "de" }
        audience: { name: { ne: null } }
        tags: { elemMatch: { name: { ne: null } } }
      }
      sort: { fields: date, order: DESC }
    ) {
      nodes {
        ...Article
      }
    }
    audiences: allContentfulAudience(
      filter: { node_locale: { eq: "de" } }
      sort: { fields: order, order: ASC }
    ) {
      nodes {
        ...Audience
      }
    }
    tags: allContentfulTag(filter: { node_locale: { eq: "de" } }) {
      nodes {
        ...Tag
      }
    }
  }
`
